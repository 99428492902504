import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import PopupLayout from 'layouts/PopupLayout';
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import EmpoweredSelector from 'components/EmpoweredSelector';
import CustomInput from 'components/CustomInput';
import { getUser } from 'helpers/user';
import { downloadFile } from 'helpers/generic';

const Table = styled.table`
	th,td {
		@media (min-width: 768px) {
			&:nth-child(1) {
				width: 80px;
				white-space: nowrap;
			}
			
			&:nth-child(2) {
				width: 100px;
				white-space: nowrap;
			}
			
			&:nth-child(3) {
				width: 300px;
				white-space: nowrap;
			}

			&:nth-child(5) {
				min-width: 150px;
				width: 150px;
			}

			&:nth-child(6) {
				min-width: 150px;
				width: 150px;

				div {
					cursor: pointer;
				}
			}
		}
		
		/* @media (max-width: 768px) {
			&:nth-child(1) {
				width: 80px;
				white-space: nowrap;

				small {
					display: block;
				}
			}

			&:nth-child(11) {
				width: 40px;
			}
		} */
	}
`;

let axiosCancelToken = null;
let searchTimeout = null;

export default function IORegistry() {
	const [queryParams] = useSearchParams();

	let pageNo = queryParams.get('page') ?? undefined;
	let user_id = parseInt(queryParams.get('user_id')) > 0 ? queryParams.get('user_id') : null;
	let community_id = parseInt(queryParams.get('community_id')) > 0 ? queryParams.get('community_id') : '';

	let [registries, setRegistries] = useState({});
	let [sessionLoaded, setSessionLoaded] = useState(false);

	let [search, _setSearch] = useState(queryParams.get('search') ?? undefined);
	let [searchFix, setSearchFix] = useState(search);
	let setSearch = (value, timeout = 1000) => {
		// Timeout to ajax
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearchFix(value);
			setPage(1);
		}, timeout);

		// Set search
		_setSearch(value);
		window.sessionStorage.setItem('search', value);
	}

	let [sortDirection, _setSortDirection] = useState('desc');
	let setSortDirection = (value) => {
		_setSortDirection(value);
		window.sessionStorage.setItem('sortDirection', value);
	}

	let [sortField, _setSortField] = useState('date');
	let setSortField = (value) => {
		_setSortField(value);
		window.sessionStorage.setItem('sortField', value);
	}

	let [community, _setCommunity] = useState({
		id: community_id,
		name: null
	});
	let setCommunity = (value) => {
		_setCommunity(value);
		setPage(1);
		window.sessionStorage.setItem('community', JSON.stringify(value));
	}

	let [user, _setUser] = useState({
		id: user_id,
		name: null,
	});
	let setUser = (value) => {
		_setUser(value);
		setPage(1);
		window.sessionStorage.setItem('user', JSON.stringify(value));
	}

	let [skeletonRows, setSkeletonRows] = useState(5);

	let [page, _setPage] = useState(pageNo);
	const setPage = (page) => {
		setRegistries((prev) => ({...prev, data: undefined}));
		_setPage(page);
		window.sessionStorage.setItem('page', page);
	}

	let [dateFrom, _setDateFrom] = useState(null);
	let setDateFrom = (value) => {
		_setDateFrom(value);
		setPage(1);
		window.sessionStorage.setItem('dateFrom', value);
	}

	let [dateTo, _setDateTo] = useState(null);
	let setDateTo = (value) => {
		_setDateTo(value);
		setPage(1);
		window.sessionStorage.setItem('dateTo', value);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( !sessionLoaded ) return;

		const getDataAsync = async () => {
			let params = {
				page: page,
				search: searchFix,
				community_id: community?.id,
				user_id: user?.id,
				sort: sortField,
				direction: sortDirection,
				date_from: dateFrom,
				date_to: dateTo
			};

			// Load registries list
			await axios.get('/api/manager/ioregistry/list', {
				params: params,
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setRegistries({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getDataAsync();
	}, [sessionLoaded, page, searchFix, sortField, sortDirection, community?.id, user?.id, dateFrom, dateTo]);

	useEffect(() => {
		let getDataAsync = async () => {
			let search = sessionStorage.getItem('search');
			if (search !== 'null') setSearch(search, 0);

			let sortDirection = sessionStorage.getItem('sortDirection');
			if (sortDirection !== 'null' && sortDirection !== null) setSortDirection(sortDirection);

			let sortField = sessionStorage.getItem('sortField');
			if (sortField !== 'null' && sortField !== null) setSortField(sortField);

			let page = sessionStorage.getItem('page');
			if (page !== 'null') _setPage(page);

			let dateFrom = sessionStorage.getItem('dateFrom');
			if (dateFrom !== 'null') _setDateFrom(dateFrom);

			let dateTo = sessionStorage.getItem('dateTo');
			if (dateTo !== 'null') _setDateTo(dateTo);

			let community = JSON.parse(sessionStorage.getItem('community'));
			if ( community ) _setCommunity(community);

			let user = JSON.parse(sessionStorage.getItem('user'));
			if ( user ) {
				_setUser(user);
				user_id = user?.id; // Fix: needed because on getDataFromAjax function we get data of user from db
			}

			let getDataFromAjax = async () => {
				let user = null;
				let community = null;

				let userError = false;
				let communityError = false;

				if ( user_id ) {
					await axios.get('/api/manager/users/get/' + user_id, {
						cancelToken: axiosCancelToken.token
					}).then((response) => {
						user = {id: response.data.id, name: response.data.name};
					}).catch((error) => {
						userError = true;
						if ( axios.isCancel(error) ) return;
					});	
				}

				if ( community_id ) {
					await axios.get('/api/manager/communities/get/' + community_id, {
						cancelToken: axiosCancelToken.token
					}).then((response) => {
						community = {id: response.data.id, name: response.data.name};
					}).catch((error) => {
						communityError = true;
						if ( axios.isCancel(error) ) return;
					});	
				}

				if ( user ) setUser(user);
				if ( community ) setCommunity(community);

				if (!userError && !communityError) {
					setSessionLoaded(true);
				}
			}
			await getDataFromAjax();
		}
		getDataAsync();
	}, [user_id, community_id]);

	const loadCommunities = (input, callback) => {
		axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectCommunity = (value) => {
		setCommunity({
			id: value?.id,
			name: value?.name
		});
	}

	const loadUsers = (input, callback) => {
		axios.get('/api/manager/users/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectUser = (value) => {
		setUser({
			id: value?.id,
			name: value?.name
		});
	}

	const setDatesRange = (e, range) => {
		e.preventDefault();

		let dateFrom = null;
		let dateTo = null;
		
		switch(range) {
			case 'today':
				dateFrom = moment().format('YYYY-MM-DD');
				dateTo = moment().format('YYYY-MM-DD');
			break;

			case 'yesterday':
				dateFrom = moment().subtract(1, 'days').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'days').format('YYYY-MM-DD');
			break;

			case 'thisweek':
				dateFrom = moment().startOf('week').format('YYYY-MM-DD');
				dateTo = moment().endOf('week').format('YYYY-MM-DD');
			break;

			case 'lastweek':
				dateFrom = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
			break;

			case 'thismonth':
				dateFrom = moment().startOf('month').format('YYYY-MM-DD');
				dateTo = moment().endOf('month').format('YYYY-MM-DD');
			break;

			case 'lastmonth':
				dateFrom = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
			break;

			case 'thisyear':
				dateFrom = moment().startOf('year').format('YYYY-MM-DD');
				dateTo = moment().endOf('year').format('YYYY-MM-DD');
			break;

			case 'lastyear':
				dateFrom = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
			break;

			default:
		}

		setDateFrom(dateFrom);
		setDateTo(dateTo);
	}

	const downloadRegistryFile = (registry) => {
		return downloadFile('/api/manager/ioregistry/download-file/' + registry.id);
	}

	return (
		<PopupLayout>
			<section>
				<div className="page-title">
					<h1>Registro de entrada y salida</h1>

					<div className="ms-auto">
						<NavLink to="/ioregistry/add" className="btn btn-sm btn-light ms-3">Nueva entrada/salida</NavLink>
					</div>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-md-12">
							<div className="card border-0 shadow-sm">
								<div className="card-header bg-white p-3">
									<div className="row">
										<div className="col-md-3 col-xl-2 mb-2">
											<CustomInput label="Buscar" type="search" className="form-control form-control-sm" onChange={(e) => setSearch(e.target.value)} value={search ?? ''} />
										</div>

										<div className="col-md-3 col-xl-2 mb-2">
											<EmpoweredSelector
												load={loadCommunities}
												onChange={(value) => selectCommunity(value)}
												timeout={250}
												label={community?.name ?? ''}
												placeholder="- Comunidad -"
												showPlaceholderHelper={true}
												value={community?.id}
											/>
										</div>

										<div className="col-md-3 col-xl-2 mb-2">
											<EmpoweredSelector
												load={loadUsers}
												onChange={(value) => selectUser(value)}
												timeout={250}
												label={user?.name ?? ''}
												placeholder="- Gestor -"
												showPlaceholderHelper={true}
												value={user?.id}
											/>
										</div>

										<div className="col-md-4 mb-2">
											<div className="input-group input-group-sm">
												<span className="input-group-text">
													Fechas
												</span>
												<input type="date" className="form-control form-control-sm" placeholder="Desde" value={dateFrom ?? ''} onChange={(e) => setDateFrom(e.target.value)} />
												<input type="date" className="form-control form-control-sm" placeholder="Hasta" value={dateTo ?? ''} onChange={(e) => setDateTo(e.target.value)} />
												<span className="input-group-btn">
													<button className="btn btn-sm btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
													<ul className="dropdown-menu dropdown-menu-end">
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'today')}>Hoy</a></li>
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'yesterday')}>Ayer</a></li>
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'thisweek')}>Esta semana</a></li>
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'lastweek')}>Semana pasada</a></li>
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'thismonth')}>Este mes</a></li>
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'lastmonth')}>Mes pasado</a></li>
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'thisyear')}>Este año</a></li>
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'lastyear')}>Año pasado</a></li>
													</ul>
												</span>
											</div>
										</div>
										<div className="col-md-2 mt-2 mb-2 mt-xl-2">
											{/* <button className="btn btn-sm btn-light" onClick={() => setShowExportModal(true)}>Exportar</button>	 */}
										</div>
									</div>
								</div>
								<div className="card-body p-0 pb-1">
									<div className="table-responsive table-responsive-carded">
										<Table className="table table-hover table-sortable table-carded">
											<thead>
												<tr>
													<ThSortable direction={sortDirection} active={sortField === 'number.full'} onClick={() => sortTableClick('number.full')}>Nº</ThSortable>
													<ThSortable direction={sortDirection} active={sortField === 'date'} onClick={() => sortTableClick('date')}>Fecha</ThSortable>
													<ThSortable direction={sortDirection} active={sortField === 'origin.name'} onClick={() => sortTableClick('origin.name')}>Origen</ThSortable>
													<ThSortable direction={sortDirection} active={sortField === 'description'} onClick={() => sortTableClick('description')}>Tipo</ThSortable>
													<ThSortable direction={sortDirection} active={sortField === 'user.name'} onClick={() => sortTableClick('user.name')}>Usuario</ThSortable>
													<ThSortable direction={sortDirection} active={sortField === 'file_name'} onClick={() => sortTableClick('file_name')}>Archivo</ThSortable>
												</tr>
											</thead>
											<tbody>
												{ registries.data?.length > 0 &&
													registries.data.map((el, idx) => {
														return ( 
															<React.Fragment key={idx}>
																<tr>
																	<td>{el.number_full}</td>
																	<td>
																		{ moment(el.date).format('DD-MM-YYYY') }
																		<small className="d-block">{ moment('2024-12-11 ' + el.time).format('HH:mm') }</small>
																	</td>
																	<td>
																		<small>{el.origin_type === 'community' && 'Comunidad'}{el.origin_type === 'external' && 'Externo'}</small>
																		<div>{el.origin_name ?? '-'}</div>
																	</td>
																	<td>{el.description}</td>
																	<td>{el.user?.name ?? '-'}</td>
																	<td>
																		<div onClick={() => downloadRegistryFile(el)}><i className="bi bi-file-fill text-primary me-1"></i> {el.file_name}</div>
																	</td>
																</tr>
															</React.Fragment>
														);
													})
												}

												{ registries.data && !registries.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

												{ registries.data === undefined && <TrSkeleton rows={skeletonRows} columns={7} /> }
											</tbody>
										</Table>
									</div>
								</div>
								<div className="card-footer d-flex justify-content-end" id="footer-fixed">
									<div className="d-inline-block">
										<Paginator
											min={1}
											current={registries?.current_page}
											max={registries?.last_page}
											changeCallback={(page) => setPage(page)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</PopupLayout>
	);
}


