import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { useNavigate, useParams, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader, openPopupWindow, downloadFile } from 'helpers/generic';
import CustomInput from 'components/CustomInput';
import EmpoweredSelector from 'components/EmpoweredSelector';

const StyledForm = styled.section`

	.switch-wrapper {
		.text-small {
			font-size: 10px;
		}

		.btn {

			&:first-of-type {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}

			&:last-of-type {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}

			&:hover {
				color: white !important;
			}
		}
	}
`;

const File = styled.div`
	position: relative;
	height: 30px;
	display: flex;
	align-items: center;
	user-select: none;
`;

let axiosCancelToken = null;

let parentWindowProxyCallback = window.opener?.PopupProxyCallback;

export default function IORegistryForm(props) {
	const navigate = useNavigate();
	const params = useParams();

	const fileRef = useRef(null);

	const [data, setData] = useState({
		movement: 'in',
		origin_type: 'community',
		date: moment().format('YYYY-MM-DD'),
		number: null,
		description: '',
		origin: '',
		file: null
	});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	
	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback();
		};
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);

				await axios.get('/api/manager/ioregistry/get/' + params.id, {
					params: {},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData((prev) => ({
			    		...prev, 
			    		...response.data,
			    	}));
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		} else {
			
		}
	}, [params.id]);

	useEffect(() => {
		// Only generate number if new
		if ( data.id || params.id ) return;

		const getNumber = () => {
			axios.get('/api/manager/ioregistry/get-next-number', {
				params: {
					movement: data.movement
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setData(data => ({...data, number: response.data.number}));
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}
		getNumber();
	}, [params.id, data.id, data.movement]);

	const deleteRegistry = () => {
		const c = window.confirm('¿Quieres eliminar este registro?');
		if ( !c ) return;

		axios.delete('/api/manager/ioregistry/delete/' + data.id, {}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Registro borrado')
	    	navigate('/ioregistry');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (data, action = true, status) => {
		setErrors({});

		let formData = new FormData();
		for(let key in data) {
			formData.append(key, data[key]);
		}

		return axios.post('/api/manager/ioregistry/save' + (data.id ? '/' + data.id : ''), formData, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');

			setData({...response.data.registry});
			if ( action === 'back' ) {
				navigate('/ioregistry');
			} else {
				navigate('/ioregistry');
		    	// navigate('/ioregistry/edit/' + response.data.registry.id);
			}

			return true;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');

			return false;
		});
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const loadCommunities = (input, callback) => {
		axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectCommunity = (value) => {
		// Community
		let newData = {...data};
		newData.community_id = value?.id ?? null;
		newData.community = {
			id: value?.id,
			name: value?.name
		}

		// Update state
		setData(newData);
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	const openPopupWindowMouseDownFix = (e) => {
		e.preventDefault();
		e.stopPropagation();
		document.activeElement.blur();
	}

	const openFileInput = () => {		
		setData({...data, file: null});
		fileRef.current.value = null;
		fileRef.current.click();
	}

	const fileInputChange = (e) => {
		let file = e.target.files[0];
		setData({...data, file: file, file_name: file.name});
	}

	const viewFile = (e) => {
		e.preventDefault();

		return downloadFile('/api/manager/ioregistry/download-file/' + data.id);
	}

	return (
		<PopupLayout>
			{ loading && <CristalLoader /> }

			<StyledForm>
				<div className="page-title">
					<h1>Registro de entrada/salida</h1>
					<button onClick={() => navigate('/ioregistry')} className="btn btn-sm btn-light ms-auto">Volver</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-md-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-md-12">
											<div className="switch-wrapper mb-2">
												<input type="radio" className="btn-check" name="movement" id="movement_in" autoComplete="off" disabled={data.id} checked={data.movement === 'in'}  onChange={() => setDataField('movement', 'in')} />
												<label className={'btn btn-outline-primary ' + (data.movement === 'in' ? 'text-white' : 'text-primary')} htmlFor="movement_in">Entrada</label>

												<input type="radio" className="btn-check" name="movement" id="movement_out" autoComplete="off" disabled={data.id} checked={data.movement === 'out'} onChange={() => setDataField('movement', 'out')} />
												<label className={'btn btn-outline-primary ' + (data.movement === 'out' ? 'text-white' : 'text-primary')} htmlFor="movement_out">Salida</label>
											</div>

											<div className="mb-2">
												<CustomInput label="Código" className="form-control form-control-sm" value={(data.movement === 'in' ? 'ENT' : 'SAL') + '/' + (data.number ?? '')} onChange={() => null} />
											</div>

											<div className="mb-2">
												<CustomInput label="Fecha" type="date" className="form-control form-control-sm" value={data.date} onChange={() => null} />
											</div>

											<div className="mb-2">
												<CustomInput label="Tipo de documento" className="form-control form-control-sm" onChange={(e) => setDataField('description', e.target.value)} value={data.description ?? ''} />
												{ errors.description &&
													<div className="invalid-feedback d-block">{ errors.description[0] }</div>
												}
											</div>

											<div className="switch-wrapper mb-2">
												<div className="text-primary text-small">Procedencia</div> 

												<input type="radio" className="btn-check" name="origin" id="origin_community" autoComplete="off" disabled={data.id} checked={data.origin_type === 'community'}  onChange={() => setDataField('origin_type', 'community')} />
												<label className={'btn btn-sm btn-outline-primary ' + (data.origin_type === 'community' ? 'text-white' : 'text-primary')} htmlFor="origin_community">Comunidad</label>

												<input type="radio" className="btn-check" name="origin" id="origin_external" autoComplete="off" disabled={data.id} checked={data.origin_type === 'external'} onChange={() => setDataField('origin_type', 'external')} />
												<label className={'btn btn-sm btn-outline-primary ' + (data.origin_type === 'external' ? 'text-white' : 'text-primary')} htmlFor="origin_external">Externa</label>
											</div>

											{data.origin_type === 'external' &&
												<div className="mb-2">
													<CustomInput label={'Externa'} className="form-control form-control-sm" onChange={(e) => setDataField('origin', e.target.value)} value={data.origin ?? ''} />
													{ errors.origin &&
														<div className="invalid-feedback d-block">{ errors.origin[0] }</div>
													}
												</div>
											}

											{data.origin_type === 'community' &&
												<div className="mb-2">
													<EmpoweredSelector
														load={loadCommunities}
														onChange={(value) => selectCommunity(value)}
														timeout={250}
														label={
															<div>
																{data.community?.name ?? ''}&nbsp;
																{data.community?.id &&
																	<NavLink className="text-decoration-none text-reset" to={'/communities/edit/' + data.community.id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
																}
															</div>
														}
														placeholder="Comunidad"
														showPlaceholderHelper={true}
														value={data.community?.id}
													/>
													{ errors.community_id &&
														<div className="invalid-feedback d-block">{ errors.community_id[0] }</div>
													}
												</div>
											}

											<div className="mb-2">
												<File className="form-control form-control-sm" style={{cursor: 'pointer'}} onClick={(e) => !data.file_path ? openFileInput(e) : viewFile(e)}>
													{data.file_path && 
														<React.Fragment>
															<i className="bi bi-file-fill text-primary me-1"></i>
														</React.Fragment>
													}
													{data.file_name ? data.file_name : 'Seleccionar archivo'}
												</File>
												{ errors.file &&
													<div className="invalid-feedback d-block">{errors.file[0]}</div>
												}
												<input type="file" ref={fileRef} className="d-none" onChange={fileInputChange}  />
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer" id={'footer-fixed'} style={{zIndex: 3}}>
									<div className="row h-100 d-flex align-items-center">
										<div className="col-6">
											{ data.id &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteRegistry()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											<React.Fragment>
												<button className="btn btn-sm btn-primary text-white d-inline" onClick={() => saveData(data)}>Guardar</button>							
											</React.Fragment>						
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</StyledForm>
		</PopupLayout>
	);
}